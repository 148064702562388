//TONEN FEEDBACK BIJ OPDRACHT
$(document).on('click', '.feedback-button', function() {
    $(this).hide();
    $('.feedback-container').show();
})
$(document).on('click', '.close-feedback', function() {
    $('.feedback-container').hide();
    $('.feedback-button').show();
})

//TONEN EXTRA OEFENEN TBV ERK
$(document).on("click", ".erk__button", function() {

    let onderdeel = $(this).data("onderdeel");

    for(let a = 0; a < moduleERK.length; a++) {
        if (moduleERK[a].onderdeel == onderdeel) {
            let strTitel = '<b>' + moduleERK[a].onderdeel + ' op '+ moduleERK[a].niveau + ':</b> ' + moduleERK[a].instructie
            $(".erk--titel").html(strTitel);
        }
    }
    openWindow(4);

    $(".erk--items").html('');
    $('.lesmateriaal_link[data-erk*="'+onderdeel.toLowerCase()+'"]').each(function() {
        let voortgang = '<div class="voortgang">'+$(this).find('.voortgang').html()+'</div>';
        let exerciseTitel = '<div class="erk__exercsie__titel">'+$(this).find('.dropdown__item__title').html()+'</div>';
        let button = '<div class="erk__exercise__button" data-id="'+$(this).data("id")+'">Oefenen &raquo;</div>';
        $(".erk--items").append('<div class="erk--item">'+voortgang + exerciseTitel + button + '</div>');
    });

})


//NAKIJKEN
let aantalVragen = 0;

window.hideNakijkenKleuren = function() {
    $(".stelling span").removeClass("goed").removeClass("fout");
    $(".combinatie").removeClass("goed").removeClass("fout");
    $(".checkmark").removeClass("goed").removeClass("fout");
    $(".invulveld").removeClass("goed").removeClass("fout");
    $("input").removeClass("goed").removeClass("fout");
    $("td").removeClass("goed").removeClass("fout");
}

window.kleurAntwoordVeld = function() {
        showNakijken();
        $('.vowi:checked').each(function() {
            let uid = $(this).closest('tr').data("uid");

            let antwoordObj = $('input[data-uid="'+uid+'"], div[data-uid="'+uid+'"]');

            if ($(this).hasClass("goed")) {
                antwoordObj.removeClass('fout');
                antwoordObj.addClass('goed');
            }
            if ($(this).hasClass("fout")) {
                antwoordObj.removeClass('goed');
                antwoordObj.addClass('fout');
            }
        });

        $('.stelling-invul:checked').each(function() {
            let uid = $(this).closest('tr').data("uid");
            if ($(this).hasClass("goed")) {
                $('#stelling-vraag-'+uid).removeClass('fout');
                $('#stelling-vraag-'+uid).addClass('goed');
            }
            if ($(this).hasClass("fout")) {
                $('#stelling-vraag-'+uid).removeClass('goed');
                $('#stelling-vraag-'+uid).addClass('fout');
            }

        });

        $('.schema:checked').each(function() {
            let uid = $(this).closest('tr').data("uid");
            let x = $(this).closest('tr').data('x');
            let y = $(this).closest('tr').data('y');

            let antwoordObj = $('tr[data-uid="'+uid+'"').find('input[data-x="' + x + '"][data-y="'+y+'"]');

            if ($(this).hasClass("goed")) {
                antwoordObj.removeClass('fout');
                antwoordObj.addClass('goed');
            }
            if ($(this).hasClass("fout")) {
                antwoordObj.removeClass('goed');
                antwoordObj.addClass('fout');
            }
        });
        closeWindow(5);
    }

window.showNakijken = function() {
    $(".nakijken-oefening").html($("#opdracht-titel").html());
    $(".nakijken-resultaat").hide();
    $(".nakijken-zelfnakijken").hide();


    $(".exercise-panel__bottom__disabler").css('height',$('.exercises').outerHeight()).css('top', $('.exercises').position().top)

    hideNakijkenKleuren();

    let legeVelden = false;

    $("input.invulveld").each(function() {
       if ($(this).val().length == 0) {
           legeVelden = true;
           return false;
       }
    });

    $("div.invulveld").each(function() {
        if (legeVelden) { return false; }

        if ($(this).text().length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("div.drop-container").each(function() {
        if (legeVelden) { return false; }

        if ($(this).find('.combinatie').length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("table.meerkeuze").each(function() {
        if (legeVelden) { return false; }

        if ($(this).find('input[type=radio]:checked').length == 0) {
            legeVelden = true;
            return false;
        }
    })

    $("input.meerkeuze_aanvullend").each(function() {
        if (legeVelden) { return false; }
        if ($(this).val().length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("input.meerkeuze_invulveld").each(function() {
        if (legeVelden) { return false; }
        if ($(this).closest('tr').find('input[type=radio]:checked').length) {
            if ($(this).val().length == 0) {
                legeVelden = true;
                return false;
            }
        }
    });

    $("input.schemaveld").each(function() {
        if (legeVelden) { return false; }
        if ($(this).val().length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("input.input_begrippennet").each(function() {
        if (legeVelden) { return false; }
        if ($(this).val().length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $(".stelling").each(function() {
        if (legeVelden) { return false; }
        if ($(this).find('.active').length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("input.stelling-vraag").each(function() {
        if (legeVelden) { return false; }
        if ($(this).val().length == 0) {
            legeVelden = true;
            return false;
        }
    });

    $("input.kwp-input").each(function() {
        if (legeVelden) { return false; }
        if ($(this).val().length == 0) {
            legeVelden = true;
            return false;
        }
    });
    if (legeVelden) {
        toastr["error"]("Let op!<br>Vul eerst alle velden in.");
        return false;
    }

    if ($("#score").val() == '-1') {
        //SLA ANTWOORDEN OP BIJ EERSTE KEER OP NAKIJKEN
        $("#score").val(-2);
        saveAntwoorden(false);
    }
    if ($("#score").val() == '-2') {
        $("#score").val(0);
    }

    openWindow(5);

    if (vragenStack.feedback != '' && vragenStack.feedback != null) {
        $(".nakijken-feedback").show();
        $(".nakijken-feedback").html(vragenStack.feedback);
    } else {
        $(".nakijken-feedback").hide();
        $(".nakijken-feedback").html('');
    }

    $(".exercise-panel__bottom__disabler").show();

    let verbergVraagnummer = vragenStack.verbergVraagnummer;
    let vraagNummer = '';
    let nummer = 0;
    aantalVragen = 0;
    let strHTML = '';

    for (let a = 0; a < user_klas_lesmateriaal.length; a++) {
        if (user_klas_lesmateriaal[a]['nakijken'] == -1 && user_klas_lesmateriaal[a]['lesmateriaal_id'] == activeLesmateriaalId  ) {
            vragenStack.nietNakijken = 1;
        }
    }


    for (let a = 0; a < vragenStack.vragen.length; a++) {
        let vraag = vragenStack.vragen[a];
        if (vraag.soort == "begrippennet") {
            vragenStack.nietNakijken = 1;
        }
    }

    if (vragenStack.vragen.length > 0 && vragenStack.nietNakijken == 0) {

        strHTML = '<table class="antwoorden"><tr><td width="20">&nbsp;</td><td><b>Antwoord</b></td><td width="50" class="text-center"><b>Goed</b></td><td width="50" class="text-center"><b>Fout</b></td></tr>';
        for (let a = 0; a < vragenStack.vragen.length; a++) {
            let vraag = vragenStack.vragen[a];

            if (vraag.soort != "uitleg") {
                nummer++;
            }

            if (verbergVraagnummer == 0) {
                vraagNummer = nummer;
            }
            if (vragenStack.vragen.length == 1) {
                vraagNummer = '';
            }

            if (vraag.soort == "stelling") {
                for (let b = 0; b < vraag.velden.length; b++) {
                    if (b > 0) {
                        vraagNummer = '';
                    }

                    let juisteAntwoorden = '';
                    let goed = '';
                    let fout = 'checked';
                    aantalVragen++;

                    console.log(vraag.velden[b])
                    for (let c = 0; c < vraag.velden[b].items.length; c++) {

                        if (vraag.velden[b].items[c].correct == 1) {
                            let antwoordObj = $('.stelling[data-uid="' + vraag.velden[b].uid + '"]').find('.active');

                            antwoordObj.html(antwoordObj.html().replace("’", "'"));
                            vraag.velden[b].items[c].titel = vraag.velden[b].items[c].titel.replace("’", "'");

                            if (juisteAntwoorden != '') {
                                juisteAntwoorden += ', ';
                            }
                            juisteAntwoorden += vraag.velden[b].items[c].titel;

                            if (antwoordObj.html() == vraag.velden[b].items[c].titel) {
                                antwoordObj.addClass('goed');
                                goed = 'checked';
                                fout = '';
                            }
                        }
                    }

                    if (goed == '') {
                        let antwoordObj = $('.stelling[data-uid="' + vraag.velden[b].uid + '"]').find('.active');
                        antwoordObj.addClass('fout');
                    }

                    strHTML += '<tr class="antwoord-stelling" data-uid="' + vraag.velden[b].uid + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + juisteAntwoorden + '</td>' +
                        '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + vraag.velden[b].uid + '-goed" name="radio-' + vraag.velden[b].uid + '" type="radio" value="1"><label for="radio-' + vraag.velden[b].uid + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + vraag.velden[b].uid + '-fout" name="radio-' + vraag.velden[b].uid + '" type="radio" value="0"><label for="radio-' + vraag.velden[b].uid + '-fout"></label></td>' +
                        '</tr>';

                }
                if (vraag.stelling.invulveld == 1) {

                    let goed = '';
                    let fout = '';

                    let antwoord_correct = 'Beoordeel dit antwoord zelf';
                    if (vraag.stelling.invulveld_antwoord_correct != null) {
                        antwoord_correct = vraag.stelling.invulveld_antwoord_correct;
                    }

                    let antwoordObj = $('#stelling-vraag-' + vraag.stelling.uid );

                    if (vraag.stelling.automatisch == 1) {
                        if (cleanQuotes(antwoord_correct.trim()) == cleanQuotes(antwoordObj.val())) {
                            antwoordObj.addClass('goed');
                            goed = 'checked';
                        } else {
                            antwoordObj.addClass('fout');
                            fout = 'checked';
                        }

                        disabled = 'disabled';
                    } else {
                        if (nakijkenStack['radio-' + vraag.stelling.uid] != undefined && nakijkenStack['radio-' + vraag.stelling.uid] == 1) {
                            goed = 'checked';
                        }
                        if (nakijkenStack['radio-' + vraag.stelling.uid] != undefined && nakijkenStack['radio-' + vraag.stelling.uid] == 0) {
                            fout = 'checked';
                        }
                    }

                    aantalVragen++;

                    let klasse = '';
                    if (goed == '' && fout == '') {
                        klasse = 'zelf';
                    }
                    strHTML += '<tr class="antwoord-stelling-invulveld '+klasse+'" data-uid="' + vraag.stelling.uid + '">' +
                        '<td></td><td>' + antwoord_correct + '</td>' +
                        '<td class="text-center"><input class="goed stelling-invul" ' + goed + ' id="radio-' + vraag.stelling.uid + '-goed" name="radio-' + vraag.stelling.uid + '" type="radio" value="1"><label for="radio-' + vraag.stelling.uid + '-goed"></label></td><td class="text-center"><input class="fout stelling-invul" ' + fout + ' id="radio-' + vraag.stelling.uid + '-fout" name="radio-' + vraag.stelling.uid + '" type="radio" value="0"><label for="radio-' + vraag.stelling.uid + '-fout"></label></td>' +
                        '</tr>';
                }
            }
            if (vraag.soort == "combinatie") {
                for (let b = 0; b < vraag.items.length; b++) {
                    if (vraag.items[b].titel != '') {
                        aantalVragen++;
                    }
                }

                let aantalGoed = 0;
                let aantalCombinaties = 0;
                $(".combinatie-antwoord").each(function () {
                    let goed, fout = '';
                    let uid = $(this).closest('tr').data('uid');


                    let vraag = $(this).closest('tr').find('.combinatie-antwoord').html();

                    if ($(this).closest('tr').find('.combinatie').data('index') == $(this).closest('tr').find('.drop-container').data('index')) {
                        aantalGoed++;
                        goed = 'checked';
                        $(this).closest('tr').find('.combinatie').addClass('goed');
                    } else {
                        fout = 'checked';
                        $(this).closest('tr').find('.combinatie').addClass('fout');

                    }
                    if (aantalCombinaties > 0) {
                        vraagNummer = '';
                    }
                    strHTML += '<tr class="antwoord-combinatie" data-uid="' + uid + '">' +
                        '<td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + vraag + '</td>' +
                        '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + uid + '-goed" name="radio-' + uid + '" type="radio" value="1"><label for="radio-' + uid + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + uid + '-fout" name="radio-' + uid + '" type="radio" value="0"><label for="radio-' + uid + '-fout"></label></td>' +
                        '</tr>';
                    aantalCombinaties++;
                });
            }

            if (vraag.soort == "woord") {
                for (let b = 0; b < vraag.velden.length; b++) {
                    aantalVragen++;
                    if (b > 0) {
                        vraagNummer = '';
                    }

                    let veld = vraag.velden[b];
                    let goed = '';
                    let fout = '';

                    let disabled = '';

                    let antwoordObj = $('[data-uid="' + veld.uid + '"]');

                    if (vraag.woord.automatisch == 1) {
                        let done = false;

                        let antwoorden = veld.juiste_antwoord.split('\\');
                        for (let i = 0; i < antwoorden.length; i++) {
                            //console.log("juiste antwoord = ", antwoorden[i])

                            let ingevoerdeAntwoord = antwoordObj.val();

                            if (antwoordObj.hasClass("textarea")) {
                                ingevoerdeAntwoord = antwoordObj.html();
                            }
                            if (cleanQuotes(antwoorden[i].trim()) == cleanQuotes(ingevoerdeAntwoord.trim())) {
                                antwoordObj.addClass('goed');
                                goed = 'checked';
                                done = true;
                                break;
                            }

                        }
                        if (done == false) {
                            antwoordObj.addClass('fout');
                            fout = 'checked';
                        }

                        disabled = 'disabled';
                    } else {
                        if (nakijkenStack['radio-' + veld.uid] != undefined && nakijkenStack['radio-' + veld.uid] == 1) {
                            goed = 'checked';
                        }
                        if (nakijkenStack['radio-' + veld.uid] != undefined && nakijkenStack['radio-' + veld.uid] == 0) {
                            fout = 'checked';
                        }
                    }

                    let klasse = '';
                    if (goed == '' && fout == '') {
                        klasse = 'zelf';
                    }

                    strHTML += '<tr class="antwoord-woord '+klasse+'" data-uid="' + veld.uid + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + veld.juiste_antwoord + '</td>' +
                        '<td class="text-center"><input ' + disabled + ' class="goed vowi" ' + goed + ' id="radio-' + veld.uid + '-goed" name="radio-' + veld.uid + '" type="radio" value="1"><label for="radio-' + veld.uid + '-goed"></label></td><td class="text-center"><input ' + disabled + ' class="fout vowi" ' + fout + ' id="radio-' + veld.uid + '-fout" name="radio-' + veld.uid + '" type="radio" value="0"><label for="radio-' + veld.uid + '-fout"></label></td>' +
                        '</tr>';
                }
            }

            if (vraag.soort == "schema") {
                let tabel = [];
                for (let c = 0; c < vraag.items.length; c++) {
                    let item = vraag.items[c];

                    if (tabel[item.y] == undefined) {
                        tabel[item.y] = []
                    }
                    if (tabel[item.y][item.x] == undefined) {
                        tabel[item.y][item.x] = []
                    }
                    tabel[item.y][item.x] = {voorbeeldAntwoord: item.voorbeeld_antwoord, antwoord: item.antwoord};
                }

                let startY = 1;
                let startX = 1;

                if (vraag.schema.y_titels == 1) {
                    startX = 2;
                }
                if (vraag.schema.x_titels == 1) {

                    startY = 2;
                }
                let showVraagnummer = 1;

                for (let y = startY; y <= vraag.schema.aantal_y; y++) {
                    for (let x = startX; x <= vraag.schema.aantal_x; x++) {
                        let disabled, goed, fout = '';

                        if (nakijkenStack['radio-' + vraag.schema.schema_uid + '-' + y + '-' + x] != undefined && nakijkenStack['radio-' + vraag.schema.schema_uid + '-' + y + '-' + x] == 1) {
                            goed = 'checked';
                        }
                        if (nakijkenStack['radio-' + vraag.schema.schema_uid + '-' + y + '-' + x] != undefined && nakijkenStack['radio-' + vraag.schema.schema_uid + '-' + y + '-' + x] == 0) {
                            fout = 'checked';
                        }

                        if (showVraagnummer > 1) {
                            vraagNummer = '';
                        }
                        aantalVragen++;

                        let klasse = '';
                        if (goed == '' && fout == '') {
                            klasse = 'zelf';
                        }


                        strHTML += '<tr class="antwoord-schema '+klasse+'" data-x="' + x + '" data-y="' + y + '" data-uid="' + vraag.schema.schema_uid + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + tabel[y][x]['antwoord'] + '</td>' +
                            '<td class="text-center"><input ' + disabled + ' class="goed schema" ' + goed + ' id="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '-goed" name="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '" type="radio" value="1"><label for="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '-goed"></label></td><td class="text-center"><input ' + disabled + ' class="fout schema" ' + fout + ' id="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '-fout" name="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '" type="radio" value="0"><label for="radio-' + vraag.schema.schema_uid + '-' + y + '-' + x + '-fout"></label></td>' +
                            '</tr>';

                        showVraagnummer++;
                    }
                }
            }

            if (vraag.soort == "meerkeuze") {
                console.log(vraag);
                for (let b = 0; b < vraag.items.length; b++) {
                    let item = vraag.items[b];

                    if (item.correct == 1) {
                        aantalVragen++;

                        let goed, fout = '';
                        let disabled = 'disabled';

                        let antwoordObj = $('#radio_' + vraag.meerkeuze.id + ':checked');

                        if (antwoordObj.length) {
                            if (antwoordObj.data("uid") == item.item_uid) {
                                antwoordObj.parent().find('.checkmark').addClass('goed');
                                goed = 'checked';
                            } else {
                                antwoordObj.parent().find('.checkmark').addClass('fout');
                                fout = 'checked';
                            }
                        }

                        strHTML += '<tr class="antwoord-meerkeuze" data-uid="' + item.item_uid + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + item.antwoord + '</td>' +
                            '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + item.item_uid + '-goed" name="radio-' + item.item_uid + '" type="radio" value="1"><label for="radio-' + item.item_uid + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + item.item_uid + '-fout" name="radio-' + item.item_uid + '" type="radio" value="0"><label for="radio-' + item.item_uid + '-fout"></label></td>' +
                            '</tr>';
                    }
                }
            }

            if (vraag.soort == "woordzoeker") {
                aantalVragen++;

                let goed, fout = '';
                let disabled = 'disabled';

                let aantalWoorden = $(".woordzoeker-woord").length;
                let aantalGevondenWoorden = $(".woordzoeker-woord.found").length;
                if (aantalWoorden == aantalGevondenWoorden) {
                    goed = 'checked';
                } else {
                    fout = 'checked';
                }
                $('table.woordzoeker td.correct').addClass('goed');

                strHTML += '<tr><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>Je hebt ' + aantalGevondenWoorden + ' van de ' + aantalWoorden + ' woorden gevonden</td>' +
                    '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + vraag.woordzoeker.id + '-goed" name="radio-' + vraag.woordzoeker.id + '" type="radio" value="1"><label for="radio-' + vraag.woordzoeker.id + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + vraag.woordzoeker.id + '-fout" name="radio-' + vraag.woordzoeker.id + '" type="radio" value="0"><label for="radio-' + vraag.woordzoeker.id + '-fout"></label></td>' +
                    '</tr>';

                if (vraag.woordzoeker.overblijvend == 1) {
                    aantalVragen++;

                    let goed, fout = '';

                    if ($('#woordzoeker-overblijvend-antwoord-' + vraag.woordzoeker.id).val() == vraag.woordzoeker.overblijvend_woord) {
                        goed = 'checked';
                        $('#woordzoeker-overblijvend-antwoord-' + vraag.woordzoeker.id).addClass("goed");
                    } else {
                        fout = 'checked';
                        $('#woordzoeker-overblijvend-antwoord-' + vraag.woordzoeker.id).addClass("fout");
                    }

                    strHTML += '<tr><td></td><td>' + vraag.woordzoeker.overblijvend_woord + '</td>' +
                        '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + vraag.woordzoeker.id + '-overblijvend-goed" name="radio-' + vraag.woordzoeker.id + '-overblijvend" type="radio" value="1"><label for="radio-' + vraag.woordzoeker.id + '-overblijvend-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + vraag.woordzoeker.id + '-overblijvend-fout" name="radio-' + vraag.woordzoeker.id + '-overblijvend" type="radio" value="0"><label for="radio-' + vraag.woordzoeker.id + '-overblijvend     -fout"></label></td>' +
                        '</tr>';
                }
            }

            if (vraag.soort == "kruiswoordpuzzel") {

                let juisteAntwoorden = [];

                for(let c = 0; c < vraag.kruiswoordpuzzelCellen.length; c++) {
                    let item = vraag.kruiswoordpuzzelCellen[c];

                    if (item.letter != '@') {
                        aantalVragen++;

                        let goed, fout = '';
                        let disabled = 'disabled';

                        antwoordObj = $('#kwp-input-'+(item.x-1)+'-'+(item.y-1));

                        if (antwoordObj.length) {
                            if (antwoordObj.val() == item.letter) {
                                antwoordObj.parent().addClass('goed');
                                goed = 'checked';
                            } else {
                                antwoordObj.parent().addClass('fout');
                                fout = 'checked';
                            }
                        }

                        strHTML += '<tr class="antwoord-meerkeuze" data-uid="' + (item.x-1)+'-'+(item.y-1) + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + item.letter + '</td>' +
                            '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + (item.x-1)+'-'+(item.y-1) + '-goed" name="radio-' + (item.x-1)+'-'+(item.y-1) + '" type="radio" value="1"><label for="radio-' + (item.x-1)+'-'+(item.y-1) + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + (item.x-1)+'-'+(item.y-1) + '-fout" name="radio-' + (item.x-1)+'-'+(item.y-1)+ '" type="radio" value="0"><label for="radio-' + (item.x-1)+'-'+(item.y-1) + '-fout"></label></td>' +
                            '</tr>';

                    }

                }

                for (let b = 0; b < juisteAntwoorden.length; b++) {
                    let item = vraag.items[b];

                    if (item.correct == 1) {
                        aantalVragen++;

                        let goed, fout = '';
                        let disabled = 'disabled';

                        let antwoordObj = $('#radio_' + vraag.meerkeuze.id + ':checked');

                        if (antwoordObj.length) {
                            if (antwoordObj.data("uid") == item.item_uid) {
                                antwoordObj.parent().find('.checkmark').addClass('goed');
                                goed = 'checked';
                            } else {
                                antwoordObj.parent().find('.checkmark').addClass('fout');
                                fout = 'checked';
                            }
                        }

                        strHTML += '<tr class="antwoord-meerkeuze" data-uid="' + item.item_uid + '"><td><div class="td-vraagnummer">' + vraagNummer + '</div></td><td>' + item.antwoord + '</td>' +
                            '<td class="text-center"><input disabled class="goed" ' + goed + ' id="radio-' + item.item_uid + '-goed" name="radio-' + item.item_uid + '" type="radio" value="1"><label for="radio-' + item.item_uid + '-goed"></label></td><td class="text-center"><input disabled class="fout" ' + fout + ' id="radio-' + item.item_uid + '-fout" name="radio-' + item.item_uid + '" type="radio" value="0"><label for="radio-' + item.item_uid + '-fout"></label></td>' +
                            '</tr>';
                    }
                }


            }


        }
        strHTML += '</table>';
    } else {
        strHTML = 'Heb je deze opdracht gedaan? Mogelijk gaat je docent nog controleren.';
    }
    $(".nakijken-antwoorden").html(strHTML);

    if (vragenStack.vragen.length > 0 && vragenStack.nietNakijken == 0) {
        berekenScore();
    }
}

window.showAntwoorden = function(target_uid = '') {
    for (let a = 0; a < vragenStack.vragen.length; a++) {
        let vraag = vragenStack.vragen[a];

        if (vraag.soort == "woord") {
            for (let b = 0; b < vraag.velden.length; b++) {
                let veld = vraag.velden[b];
                if (veld.uid == target_uid || target_uid == '') {
                    let antwoordObj = $('[data-uid="' + veld.uid + '"]');
                    antwoordObj.val(veld.juiste_antwoord);

                    if (veld.aantal_antwoordregels > 1) {
                        antwoordObj.html(veld.juiste_antwoord);
                    }
                }
            }
        }

        if (vraag.soort == "stelling") {
            let temp_stelling = null;

            for (let b = 0; b < vraag.velden.length; b++) {
                for (let c = 0; c < vraag.velden[b].items.length; c++) {
                    if (vraag.velden[b].items[c].correct == 1) {
                        if (vraag.velden[b].uid == target_uid || target_uid == '') {
                            temp_stelling = vraag.stelling.uid;
                            $('#v-' + vraag.velden[b].items[c].uid).addClass('active');
                        }
                    }
                }
            }

            let antwoord_correct = 'Beoordeel dit antwoord zelf';
            if (vraag.stelling.invulveld_antwoord_correct != null) {
                antwoord_correct = vraag.stelling.invulveld_antwoord_correct;
            }
            if (vraag.stelling.uid == temp_stelling || target_uid == '') {
                $('#stelling-vraag-' + vraag.stelling.uid).val(antwoord_correct);
            }

        }

        if (vraag.soort == "combinatie") {
            for (let b = 0; b < vraag.items.length; b++) {
                if (b+1 == target_uid || target_uid == '') {
                    var $resetCombinatie = $('.combinatie[data-index="' + b + '"]');
                    $resetCombinatie.appendTo($('.drop-container[data-index="' + $resetCombinatie.data("index") + '"]'));
                }
            }
        }

        if (vraag.soort == "schema") {
            let tabel = [];
            for (let c = 0; c < vraag.items.length; c++) {
                let item = vraag.items[c];

                if (tabel[item.y] == undefined) {
                    tabel[item.y] = []
                }
                if (tabel[item.y][item.x] == undefined) {
                    tabel[item.y][item.x] = []
                }
                tabel[item.y][item.x] = {voorbeeldAntwoord: item.voorbeeld_antwoord, antwoord: item.antwoord};
            }

            let startY = 1;
            let startX = 1;

            if (vraag.schema.y_titels == 1) {
                startX = 2;
            }
            if (vraag.schema.x_titels == 1) {

                startY = 2;
            }

            for (let y = startY; y <= vraag.schema.aantal_y; y++) {
                for (let x = startX; x <= vraag.schema.aantal_x; x++) {
                    if (x+'_'+y == target_uid || target_uid == '') {
                        $('input[data-x="' + x + '"][data-y="' + y + '"]').val(tabel[y][x].antwoord)
                    }
                }
            }
        }

        if (vraag.soort == "meerkeuze") {
            for (let b = 0; b < vraag.items.length; b++) {
                if (vraag.meerkeuze.meerkeuze_uid == target_uid || target_uid == '') {
                    let item = vraag.items[b];

                    if (item.correct == 1) {
                        $('input[data-uid="' + item.item_uid + '"]').prop("checked", true);
                    }
                }
            }
        }

        if (vraag.soort == "kruiswoordpuzzel") {

            let juisteAntwoorden = [];

            for(let c = 0; c < vraag.kruiswoordpuzzelCellen.length; c++) {
                let item = vraag.kruiswoordpuzzelCellen[c];

                if (item.letter != '@') {
                    $('#kwp-input-'+(item.x-1)+'-'+(item.y-1)).val(item.letter);
                }
            }

        }
    }
}

window.hideAntwoorden = function() {
    $(".exercises").find('input').val('');
    $(".exercises").find('.textarea').html('');
    $(".exercises").find('span').removeClass('active');
    $('input[type=radio]').prop("checked", false);

    for (let a = 0; a < vragenStack.vragen.length; a++) {
        let vraag = vragenStack.vragen[a];

        if (vraag.soort == "combinatie") {
            for (let b = 0; b < vraag.items.length; b++) {
                var $resetCombinatie = $('.combinatie[data-index="'+b+'"]');
                $resetCombinatie.appendTo($('.combinatie-container[data-index="' + $resetCombinatie.data("index") + '"]'));
            }
        }
    }

}

window.berekenScore = function () {
    console.log("Bereken");
    let aantalGoed = 0;
    let aantalFout = 0;

    nakijkenStack = [];
    $(".antwoorden input[type='radio']:checked").each(function() {
        nakijkenStack[$(this).attr("name")] = $(this).val();
        if ($(this).hasClass("goed")) {
            aantalGoed += 1;
        } else if ($(this).hasClass("fout")) {
            aantalFout += 1;
        }
    });

    $("#nakijken").val(JSON.stringify( Object.assign({}, nakijkenStack)));

    if (aantalVragen > 0 || vragenStack.nietNakijken == 1) {

        if ((aantalFout+aantalGoed) < aantalVragen) {
            $(".nakijken-resultaat").hide();
            $(".nakijken-zelfnakijken").show();
            $(".vraag-opnieuw").addClass("disabled");
            $(".vraag-klaar").addClass("disabled");
        } else {
            let percentage = Math.round((aantalGoed / aantalVragen) * 100);
            $("#score").val(percentage);
            $(".nakijken-resultaat-percentage").html(percentage);
            $(".nakijken-resultaat").show();
            $(".nakijken-zelfnakijken").hide();
            $(".vraag-opnieuw").removeClass("disabled");
            $(".vraag-klaar").removeClass("disabled");
        }

        if (vragenStack.nietNakijken == 1) {
            $(".nakijken-resultaat").hide();
        }

    }

}
$(document).on('click', '.vraag-opnieuw', function () {
    if (!$(this).hasClass("disabled")) {
        hideNakijkenKleuren();

        if (vragenStack.vragen.length > 0 && vragenStack.nietNakijken == 0) {
            berekenScore();
        } else {

        }
        $("#status").val('bezig');
        //$("#button-save-antwoorden").trigger('click');
        $(".exercise-panel__bottom__disabler").hide();
        closeWindow(5);
    }
})
$(document).on('click', '.vraag-klaar', function () {
    if (!$(this).hasClass("disabled")) {

            if (vragenStack.vragen.length > 0 && vragenStack.nietNakijken == 0) {
                $("#status").val('afgerond');
                berekenScore();
            } else {
                $("#status").val('nakijken');
                $("#score").val(80);
            }


            $("#button-save-antwoorden").trigger('click');
            $(".exercise-panel__bottom__disabler").hide();
            closeWindow(5);

            let score = $("#score").val();
            let newIcon = '';

            if (score <= 20) {
                newIcon = '<svg class="checkbox"><use xlink:href="img/sprite.svg#checkbox-0-20"></use></svg>';
            } else if (score > 20 && score <= 40) {
                newIcon = '<svg class="checkbox"><use xlink:href="img/sprite.svg#checkbox-21-40"></use></svg>';
            } else if (score > 40 && score <= 60) {
                newIcon = '<svg class="checkbox"><use xlink:href="img/sprite.svg#checkbox-41-60"></use></svg>';
            } else if (score > 20 && score <= 80) {
                newIcon = '<svg class="checkbox"><use xlink:href="img/sprite.svg#checkbox-61-80"></use></svg>';
            } else if (score > 80 && score <= 100) {
                newIcon = '<svg class="checkbox"><use xlink:href="img/sprite.svg#checkbox-81-100"></use></svg>';
            }

            $('.dropdown__item[data-id="' + activeLesmateriaalId + '"]').find('.icon').html(newIcon);
            $('.dropdown__item[data-id="' + activeLesmateriaalId + '"]').find('.percentage').html($("#score").val() + '%');

    }
});

$(document).on('click', '.goed, .fout', function() {
    $(this).closest('tr').removeClass('zelf');
    berekenScore();
})
$(document).on('mouseover', '.antwoord-schema', function() {
    let uid = $(this).data("uid");
    let x = $(this).data('x');
    let y = $(this).data('y');
    $('tr[data-uid="'+uid+'"').find('input[data-x="' + x + '"][data-y="'+y+'"]').addClass('highlight');
})
$(document).on('mouseout', '.antwoord-schema', function() {
    let uid = $(this).data('uid');
    let x = $(this).data('x');
    let y = $(this).data('y');
    $('tr[data-uid="'+uid+'"').find('input[data-x="' + x + '"][data-y="'+y+'"]').removeClass('highlight');
})
$(document).on('mouseover', '.antwoord-woord', function() {
    let uid = $(this).data("uid");
    $('input[data-uid="'+uid+'"').addClass('highlight');
    $('div[data-uid="'+uid+'"]').addClass('highlight');
})
$(document).on('mouseout', '.antwoord-woord', function() {
    let uid = $(this).data("uid");
    $('input[data-uid="'+uid+'"').removeClass('highlight');
    $('div[data-uid="'+uid+'"]').removeClass('highlight');
})
$(document).on('mouseover', '.antwoord-stelling', function() {
    let uid = $(this).data("uid");
    $('.stelling[data-uid="'+uid+'"').addClass('highlight');
})
$(document).on('mouseout', '.antwoord-stelling', function() {
    let uid = $(this).data("uid");
    $('.stelling[data-uid="'+uid+'"').removeClass('highlight');
})
$(document).on('click', '.stelling-invul', function() {

    let uid = $(this).closest('tr').data("uid");
    if ($(this).hasClass("goed")) {
        $('#stelling-vraag-'+uid).removeClass('fout');
        $('#stelling-vraag-'+uid).addClass('goed');
    }
    if ($(this).hasClass("fout")) {
        $('#stelling-vraag-'+uid).removeClass('goed');
        $('#stelling-vraag-'+uid).addClass('fout');
    }
})
$(document).on('mouseover', '.antwoord-combinatie', function() {
    let uid = $(this).data("uid");
    $('tr[data-uid="'+uid+'"').find('.combinatie-antwoord').addClass('highlight');
})
$(document).on('mouseout', '.antwoord-combinatie', function() {
    let uid = $(this).data("uid");
    $('tr[data-uid="'+uid+'"').find('.combinatie-antwoord').removeClass('highlight');
})
$(document).on('click', '.schema', function() {

    let uid = $(this).closest('tr').data("uid");
    let x = $(this).closest('tr').data('x');
    let y = $(this).closest('tr').data('y');

    let antwoordObj = $('tr[data-uid="'+uid+'"').find('input[data-x="' + x + '"][data-y="'+y+'"]');

    if ($(this).hasClass("goed")) {
        antwoordObj.removeClass('fout');
        antwoordObj.addClass('goed');
    }
    if ($(this).hasClass("fout")) {
        antwoordObj.removeClass('goed');
        antwoordObj.addClass('fout');
    }
})
$(document).on('click', '.vowi', function() {

    let uid = $(this).closest('tr').data("uid");

    let antwoordObj = $('input[data-uid="'+uid+'"], div[data-uid="'+uid+'"]');

    if ($(this).hasClass("goed")) {
        antwoordObj.removeClass('fout');
        antwoordObj.addClass('goed');
    }
    if ($(this).hasClass("fout")) {
        antwoordObj.removeClass('goed');
        antwoordObj.addClass('fout');
    }
});
$(document).on('mouseover', '.antwoord-stelling-invulveld', function() {
    let uid = $(this).data("uid");
    $('#stelling-vraag-'+uid).addClass('highlight');
})
$(document).on('mouseout', '.antwoord-stelling-invulveld', function() {
    let uid = $(this).data("uid");
    $('#stelling-vraag-'+uid).removeClass('highlight');
})


$(document).on('click', '#button-nakijken', function() {
    showNakijken();
});


//OPSLAAN ANTWOORDEN
$(document).on('click', '#button-save-antwoorden', function() {
    saveAntwoorden();
});
$(document).on('click', '#woord-email-button', function() {
    $("#button-nakijken").trigger('click');
});
window.saveAntwoorden = function(showAlert = true) {
    let antwoorden = [];

    if (!vragenStack.vragen) {
        return;
    }

    for (let a = 0; a < vragenStack.vragen.length; a++) {

        let vraag = vragenStack.vragen[a];

        if (vraag.soort == "begrippennet") {
            let vraagUid = vraag.begrippennet.begrippennet_uid;
            let invulvelden = [];
            let woord_midden = '';

            $('.input_begrippennet').each(function() {
                if ($(this).attr("id") != "web_midden") {
                    invulvelden.push({tekst: $(this).val()});
                } else {
                    woord_midden = $(this).val();
                }
            });

            antwoorden.push({vraag_soort: 'begrippennet', id:vraag.begrippennet.id, woord_midden: woord_midden, invulvelden: invulvelden });
        }


        if (vraag.soort == "meerkeuze") {
            let checked = $('input[name="radio_'+vraag.meerkeuze.id+'"]:checked').val();

            let invulveld = $('#meerkeuze_invulveld_'+vraag.meerkeuze.id).val();
            let aanvullende = $('#meerkeuze_aanvullende_'+vraag.meerkeuze.id).val();

            antwoorden.push({vraag_soort: 'meerkeuze', id:vraag.meerkeuze.id, checked: checked, invulveld: invulveld, aanvullende: aanvullende });
        }

        if (vraag.soort == "stelling") {
            let vraagUid = vraag.stelling.uid;
            let geselecteerdeStellingen = [];
            let invulveld = '';

            $('.stelling_'+vraagUid+' .stelling').each(function() {
                let uid = $(this).data('uid');
                $(this).find('.active').each(function() {
                    geselecteerdeStellingen.push($(this).attr("id"));
                });
                if ($('#stelling-vraag-'+vraagUid).length) {

                    invulveld = $("#stelling-vraag-"+vraagUid).val();
                }
            });

            antwoorden.push({vraag_soort: 'stelling', id:vraag.stelling.id, geselecteerdeStellingen: geselecteerdeStellingen, invulveld: invulveld });
        }

        if (vraag.soort == "woordzoeker") {
            let gekleurdeBlokjes = [];
            let gevondenWoorden = [];
            let ingevoerdeAntwoorden = [];

            $(".woordzoeker-"+vraag.woordzoeker.id).find(".woordzoeker-letter").each(function() {
                if ($(this).parent().hasClass("correct")) {
                    gekleurdeBlokjes.push({x: $(this).data("x"), y:$(this).data("y") });
                }
            });
            $(".woordzoeker-"+vraag.woordzoeker.id).find(".woordzoeker-woord.found").each(function() {
                gevondenWoorden.push({woordUid: $(this).data("id") });
            });
            $(".woordzoeker-"+vraag.woordzoeker.id).find(".woordzoeker-woord-vraag").each(function() {
                ingevoerdeAntwoorden.push({woordUid: $(this).data("id"), antwoord: $(this).val() });
            });


            antwoorden.push({vraag_soort: 'woordzoeker', id:vraag.woordzoeker.id, gekleurde_blokjes: gekleurdeBlokjes, gevonden_woorden: gevondenWoorden,ingevoerde_antwoorden:ingevoerdeAntwoorden, overblijvend_woord: $('#woordzoeker-overblijvend-antwoord-'+vraag.woordzoeker.id).val() });
        }

        if (vraag.soort == "woord") {
            let vraagUid = vraag.woord.uid;
            let invulvelden = [];

            $('.woord_'+vraagUid+' .invulveld').each(function() {
                let id = $(this).attr("id");

                if ($(this).hasClass("textarea")) {
                    invulvelden.push({id:id, tekst: $(this).html() });
                } else {
                    invulvelden.push({id:id, tekst: $(this).val() });
                }
            });

            antwoorden.push({vraag_soort: 'woord', id:vraag.woord.id, invulvelden: invulvelden });

        }

        if (vraag.soort == "combinatie") {
            let combinaties = [];
            $(".drop-container").find('.combinatie').each(function() {
                combinaties.push({combinatie_id: $(this).closest('.drag-container').data("combinatie-id"), combinatie_index:$(this).data("index"), drop_index:$(this).parent().data("index") });
            });
            antwoorden.push({vraag_soort: 'combinatie', id:vraag.combinatie.id, combinaties: combinaties });
        }


        if (vraag.soort == "schema") {
            let schema = [];

            $(".schemaveld").each(function() {
                if ($(this).val() != "") {

                    schema.push({ vraag:$(this).data("vraag"), x:$(this).data("x"), y:$(this).data("y"), tekst:$(this).val() });
                }
            });

            antwoorden.push({vraag_soort: 'schema', id:vraag.schema.id, schema: schema });
        }

        if (vraag.soort == "kruiswoordpuzzel") {
            let kruiswoordpuzzel = [];

            $("#kruiswoordpuzzel-" + vraag.kruiswoordpuzzel.kruiswoordpuzzel_uid + " .kwp-input").each(function () {
               // console.log($(this))
                if ($(this).val() != "") {

                    kruiswoordpuzzel.push({
                        x: $(this).data("x"),
                        y: $(this).data("y"),
                        tekst: $(this).val()
                    });
                }
            });
            antwoorden.push({vraag_soort: 'kruiswoordpuzzel', id:vraag.kruiswoordpuzzel.id, kruiswoordpuzzel: kruiswoordpuzzel });
            //console.log("Antwoorden = ",antwoorden);
        }

    }

    $("#antwoorden").val(JSON.stringify(antwoorden));

    let form = $("#opdracht-form");
    let data = new FormData(form[0]);

    let request = $.ajax({
        url: '/content/save',
        method: 'POST',
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        enctype: 'multipart/form-data',
        data: data,
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {
            if (showAlert) {
                toastr["success"]("Gelukt!<br>" + response.message)
            }
        } else {
            toastr["error"]("Fout!<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout!<br>" + e.statusText)
    });
}

/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
window.multiSort = function(array, sortObject = {}) {
    const sortKeys = Object.keys(sortObject);

    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
        return array;
    }

    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
        sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
    }

    const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;

        if (a === b) { // If the values are the same, do not switch positions.
            return 0;
        }

        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
    };

    return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;

        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
            const key = sortKeys[index];

            if (key) {
                const direction = sortObject[key];

                sorted = keySort(a[key], b[key], direction);
                index++;
            }
        }

        return sorted;
    });
}

//DRAG & DROP
let lastX;
let lastY;

$(document).on('mousedown touchstart', '.combinatie', function(event) {
    lastX = 0;
    lastY = 0;
    $(this).addClass('movable');
    $(this).appendTo($('.drag-container'));
    if (event.type == 'touchstart') {
        $(".panel-wrapper").addClass("noscroll");
    }
});

$(document).on('mouseup touchend touchcancel touchendoutside', function(e) {
    $(".panel-wrapper").removeClass("noscroll");

    if (e.type == 'touchend') {
        $(document).trigger("mouseup");
    }

    $this = $(".movable");

    if ($this.length) {
        let currentX = lastX;
        let currentY = lastY;

        let dropTarget = $('.combinatie-container[data-index="' + $this.data("index") + '"]');

        $(".drop-container").each(function() {
            let left = $(this).offset().left;
            let right = $(this).offset().left + $(this).width();
            let top = $(this).offset().top;
            let bottom = $(this).offset().top + $(this).height();

            if (left <= currentX && right >= currentX && top <= currentY && bottom >= currentY) {
                dropTarget = $(this);
            }
        });

        if (dropTarget.find('.combinatie').length) {
            let $resetCombinatie = dropTarget.find('.combinatie');
            $resetCombinatie.appendTo($('.combinatie-container[data-index="' + $resetCombinatie.data("index") + '"]'));
        }
        $this.removeClass('movable');
        $this.css('top','0px').css('left','0px')
        $this.appendTo(dropTarget);
    }
    $(".panel-wrapper").removeClass("noscroll");
});

$(document).on('mousemove touchmove', function(e) {
    if ($('.movable').length) {
        var currentX, currentY = 0;
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX;
            currentY = e.touches[0].clientY;
        } else {
            currentX = e.clientX ;
            currentY = e.clientY ;
        }

        lastX = currentX;
        lastY = currentY;


        var thisX = currentX - $('.movable').width() / 2,
            thisY = currentY - $('.movable').height() / 2;


        if ($('.movable').closest('.drag-container').offset().left - 10 > thisX) {
            thisX = $('.movable').closest('.drag-container').offset().left - 10;
        }
        if ($('.movable').closest('.drag-container').offset().top - 10 > thisY) {
            thisY = $('.movable').closest('.drag-container').offset().top - 10;
        }
        if ($('.movable').closest('.drag-container').offset().top + $('.movable').closest('.drag-container').height() - $('.movable').height() - 20 < thisY) {
            thisY = $('.movable').closest('.drag-container').offset().top + $('.movable').closest('.drag-container').height() - $('.movable').height() -20
        }
        $('.movable').offset({
            left: thisX,
            top: thisY
        });

        /*
        if (thisY+20 > ($('.movable').closest('.panel-wrapper').height() + $('.movable').closest('.panel-wrapper').offset().top)) {
            let newScrollTop = $('.movable').closest('.panel-wrapper').scrollTop()+30;

            $('.movable').closest('.panel-wrapper').scrollTop(newScrollTop);
        }
         */

        if (thisY-20 < $('.movable').closest('.exercise-panel__bottom').offset().top) {
            let newScrollTop = $('.movable').closest('.exercise-panel__bottom').scrollTop()-20;

            $('.movable').closest('.exercise-panel__bottom').scrollTop(newScrollTop);
        }

        if (thisY+20 > ($('.movable').closest('.exercise-panel__bottom').height() + $('.movable').closest('.exercise-panel__bottom').offset().top)) {
            let newScrollTop = $('.movable').closest('.exercise-panel__bottom').scrollTop()+20;

            $('.movable').closest('.exercise-panel__bottom').scrollTop(newScrollTop);
        }

    }
});

//WOORDZOEKER
let woordzoekerActive = false;
let woordzoekerId = 0;
let woordzoekerStack = [];

$(document).on('mousedown', '.woordzoeker-letter', function(e) {
    woordzoekerActive = true;

    target = document.elementFromPoint(e.pageX, e.pageY);

    if ($(target).attr('class') == "woordzoeker-letter" && woordzoekerActive) {
        woordzoekerId = $(target).closest('.woordzoeker').data("id");
        $(target).parent().addClass("active");

        if ($.inArray($(target).attr("id"), woordzoekerStack) == -1)
            woordzoekerStack.push($(target).attr("id"));
    }

    e.preventDefault();
});
$(document).on('mouseup touchend', function(e) {
    woordzoekerActive = false;

    let gevondenWoord = "";
    for(let z = 0; z < woordzoekerStack.length; z++) {
        gevondenWoord += $('#'+woordzoekerStack[z]).html();
    }

    let gevonden = false;
    $(".woordzoeker-"+woordzoekerId).find(".woordzoeker-woord").each(function() {
        if ($(this).html().toLowerCase() == gevondenWoord.toLowerCase() && !$(this).hasClass('found')) {
            $(this).addClass("found");
            gevonden = true;
            return false;
        }
    })

    if (gevonden) {
        $("table.woordzoeker td.active").addClass("correct").removeClass("active");
    } else {
        $("table.woordzoeker td.active").removeClass("active");
    }
    woordzoekerStack = [];
    woordzoekerId = 0;
});

$(document).on('mousemove', '.woordzoeker-letter', function(e) {
    e.preventDefault();

    target = document.elementFromPoint(e.pageX, e.pageY);

    if ($(target).attr('class') == "woordzoeker-letter" && woordzoekerActive) {
        $(target).parent().addClass("active");

        if ($.inArray($(target).attr("id"), woordzoekerStack) == -1)
            woordzoekerStack.push($(target).attr("id"));
    }
    e.stopPropagation();
    return true;
});

(function ($){
    var check=false, isRelative=true;

    $.elementFromPoint = function(x,y)
    {
        if(!document.elementFromPoint) return null;

        if(!check)
        {
            var sl;
            if((sl = $(document).scrollTop()) >0)
            {
                isRelative = (document.elementFromPoint(0, sl + $(window).height() -1) == null);
            }
            else if((sl = $(document).scrollLeft()) >0)
            {
                isRelative = (document.elementFromPoint(sl + $(window).width() -1, 0) == null);
            }
            check = (sl>0);
        }

        if(!isRelative)
        {
            x += $(document).scrollLeft();
            y += $(document).scrollTop();
        }

        return document.elementFromPoint(x,y);
    }

})(jQuery);



window.cleanHTML = function(html) {
    var html = html.replaceAll("<br>","||br||");
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    html = tmp.textContent||tmp.innerText;
    return html.replaceAll("||br||","<br>");

}

window.cleanQuotes = function(input) {
    if (input != undefined && input != null && input != '') {
        return input.replaceAll('’', '\'');
    } else {
        return '';
    }
}
